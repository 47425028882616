import React, { useRef, useEffect, useState } from "react";
import { CaretDown, CaretUp, UploadIcon } from "../assets";
import { ChatModelSelection } from "./ChatModelSelection";

export function ModelSettings({ availableModels, selectedModel, changeModel }) {
  const models = availableModels;

  const changeModelPopupRef = useRef(null);

  const [changeModelPopupOpen, setIsChangeModelPopupOpen] =
    React.useState(false);

  const onModelChanged = (model) => {
    changeModel(model);
    setIsChangeModelPopupOpen(false);
  };

  const togglePopup = () => {
    setIsChangeModelPopupOpen(!changeModelPopupOpen);
  };

  return (
    <>
      <div className=" self-stretch justify-start items-start gap-4 inline-flex">
        <div className=" rounded-md justify-start items-center gap-2 flex">
          <div className=" text-center text-[#e7e9e9] text-sm font-normal">
            Model
          </div>
          <div className="relative flex flex-col items-center">
            <div
              className="flex justify-between items-center text-white text-sm font-normal cursor-pointer"
              onClick={togglePopup}
            >
              <div className="model-name">{selectedModel.modelName}</div>
              <div className="caret-icon w-3 h-3 relative origin-top-left ml-1">
                {changeModelPopupOpen ? <CaretUp /> : <CaretDown />}
              </div>
            </div>

            {changeModelPopupOpen && (
              <div className="popup absolute bottom-[2rem]">
                <ChatModelSelection
                  ref={changeModelPopupRef}
                  models={models}
                  selectedModel={selectedModel}
                  changeModel={onModelChanged}
                />
              </div>
            )}
          </div>
        </div>
        <div className=" rounded-md justify-start items-center gap-2 flex">
          <div className="CustomInstructions text-center text-[#e7e9e9] text-sm font-normal">
            Custom Instructions
          </div>
          <div className=" text-white text-sm font-normal">None</div>
          <div className="caret-icon w-3 h-3 relative origin-top-left ml-1">
            <CaretDown />
          </div>
        </div>
        <div className=" justify-start items-center gap-2 flex">
          <div className=" text-center text-[#e7e9e9] text-sm font-normal">
            Transfer to Another Tool
          </div>
          <div className=" w-4 h-4 p-[1.25px] justify-center items-center flex">
            <img className=" w-[13.50px] h-[13.50px]" src={UploadIcon} />
          </div>
        </div>
      </div>
    </>
  );
}
