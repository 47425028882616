import styled from "styled-components";
import { SendIcon } from "../../assets";
import { greyColor } from "../../styles/shared_styles";

export const SendIconDisabled = styled(SendIcon)`
  #paper-plane {
    fill: ${greyColor}
  }
`;

export const VerticalLine = styled.div`
  width: 1rem;
  height: 0;
  border: 1px solid ${greyColor};
  transform: rotate(90deg);

  @media (min-width: 768px) {
    width: 1.25rem;
  }
`;