import { Logo } from "../../assets";

export function PageHeader() {
  return (
    <>
      <div className="w-[1061px] px-10 py-6 bg-[#313131] border-b border-[#313131] justify-end items-start gap-6 inline-flex">
        <div className="grow shrink basis-0 h-12 justify-start items-center gap-2 flex">
          <div className="justify-start items-center gap-6 flex">
            <div className="w-12 h-12 justify-center items-center flex">
              <div className="w-12 h-12 relative">
                <div className="w-12 h-12 absolute left-0 top-0 bg-[#222222] rounded-full border border-[#222222] flex items-center justify-center">
                  <img src={Logo} alt="gpt-web-logo" className="w-6 h-6" />
                </div>
              </div>
            </div>
            <div className="text-white text-[32px] font-semibold font-['Cormorant'] leading-9">
              GPT Web
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
