import React, { useState, useRef } from "react";
import { ChatResponseActions } from "./ChatResponseActions";
import { Logo } from "../../assets";
import ReactMarkdown from "react-markdown";

export function ChatResponse({
  availableModels,
  selectedModel,
  onModelChanged,
  responseMessage,
  showActions = false
}) {
  const responseActionChangeModel = (model) => {
    // regenerate response with selected model
    onModelChanged(model);
  };

  return (
    <>
      <div className="flex-col justify-start items-start gap-3 flex">
        <div className="justify-start items-center gap-2 inline-flex">
          <div className="Logo w-8 h-8 relative">
            <div className="w-8 h-8 absolute left-0 top-0 bg-[#222222] rounded-full border border-[#222222] flex items-center justify-center">
              <img src={Logo} alt="gpt-web-logo" className="w-4 h-4" />
            </div>
          </div>
          <div className="text-white text-sm font-medium uppercase">
            GPT Web
          </div>
          <div className="text-white text-xs font-normal">11:10 PM</div>
        </div>
        <div className="flex-col justify-start items-start gap-3 flex">
          <article className="prose text-white prose-headings:text-white prose-strong:text-white">
            <ReactMarkdown>{responseMessage}</ReactMarkdown>
          </article>
        </div>
      </div>

      {showActions && (
        <ChatResponseActions
          availableModels={availableModels}
          selectedModel={selectedModel}
          changeModel={responseActionChangeModel}
        />
      )}
    </>
  );
}
