import "./App.css";
import { PageHeader } from "./components/PageHeader/PageHeader";
import { ChatPage } from "./pages/Chat";

function App() {
  return (
    <div className="App flex-col flex justify-stretch h-screen">
      <PageHeader className="flex-0 w-full" />
      <ChatPage />
    </div>
  );
}

export default App;
