import React, { useRef } from "react";
import {
  CaretUp,
  CaretDown,
  CopyIcon,
  DeleteIcon,
  DislikeIcon,
  LikeIcon,
  RecommendedIcon,
  RegenerateResponse,
} from "../../assets";
import { ChatModelSelection } from "../ChatModelSelection";

export function ChatResponseActions({
  availableModels,
  selectedModel,
  changeModel,
}) {
  const models = availableModels;

  const changeModelPopupRef = useRef(null);

  const [changeModelPopupOpen, setIsChangeModelPopupOpen] =
    React.useState(false);

  const onModelChanged = (model) => {
    changeModel(model);
    setIsChangeModelPopupOpen(false);
  };

  const togglePopup = () => {
    setIsChangeModelPopupOpen(!changeModelPopupOpen);
  };

  return (
    <>
      <div className="Actions justify-start items-center gap-3 inline-flex">
        <RegenerateResponse className="cursor-pointer" title="Regenerate response" />

        <CopyIcon className="cursor-pointer" title="Copy response" />

        <DeleteIcon className="cursor-pointer" title="Delete response" />

        <DislikeIcon className="cursor-pointer" title="Dislike response" />

        <LikeIcon className="cursor-pointer" title="Like response" />

        <div
          className="relative cursor-pointer self-stretch px-1 rounded justify-start items-center gap-2 flex"
          onClick={togglePopup}
        >
          <div className="self-stretch justify-start items-center flex gap-1">
            <div className="Gpt35 text-white text-xs font-normal">
              {selectedModel.modelName}
            </div>
            <RecommendedIcon />
          </div>
          {changeModelPopupOpen ? <CaretUp /> : <CaretDown />}

          {changeModelPopupOpen && (
            <div className="popup absolute bottom-[2rem]">
              <ChatModelSelection
                ref={changeModelPopupRef}
                models={models}
                selectedModel={selectedModel}
                changeModel={onModelChanged}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
