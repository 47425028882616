import React, { useState } from "react";
import { ChatInput } from "../components/ChatInput";
import { ChatResponse } from "../components/ChatResponse/ChatResponse";
import { InforIcon } from "../components/InfoIcon";
import { ModelSettings } from "../components/ModelSettings";
import { OpenAiIntegration } from "../services/openai-service";
import { ChatRequest } from "../components/ChatRequest/ChatResquest";
import { ChatMessagesContainer } from "../styles/shared_styles";

export function ChatPage() {
  const availableModels = [
    {
      modelName: "GPT-3.5",
      description: "Only textchat. Great for everyday tasks",
    },
    {
      modelName: "GPT-4",
      description:
        "Smartest model. Supports browsing, document processing, and more",
    },
    {
      modelName: "GPT-4 with Vision",
      description:
        "Smartest model. Supports browsing, document processing, and more",
    },
  ];

  const [messages, setMessages] = useState([]);

  const [selectedModel, setSelectedModel] = useState(availableModels[0]);

  const changeModel = (model) => {
    setSelectedModel(model);
  };

  const onTextSubmit = async (text) => {
    setMessages([
      ...messages,
      { role: "user", content: text },
      { role: "system", content: "Generating response..." },
    ]);

    const response = await OpenAiIntegration.Instance.sendPrompt(text);

    const responseMessage = response.data?.choices?.[0]?.message?.content || "";

    if (responseMessage) {
      setMessages([
        ...messages,
        { role: "user", content: text }, // make sure user text not lost
        { role: "assistant", content: responseMessage },
      ]);
    } else {
      setMessages([
        ...messages,
        { role: "user", content: text }, // make sure user text not lost
        {
          role: "system",
          content: "Error obtaining response. Please try again.",
        },
      ]);
    }
  };

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <div className="flex-1 flex flex-col h-full">
        <div className="flex-1 overflow-y-auto justify-center items-start flex min-h-0">
          <ChatMessagesContainer className="flex md:w-[752px] items-start flex-col justify-end gap-6 w-full">
            {messages.map((message, index) =>
              message.role === "user" ? (
                <ChatRequest key={index} promptText={message.content} />
              ) : (
                <ChatResponse
                  key={index}
                  availableModels={availableModels}
                  selectedModel={selectedModel}
                  onModelChanged={changeModel}
                  responseMessage={message.content}
                  showActions={message.role === "assistant"}
                />
              )
            )}
          </ChatMessagesContainer>
        </div>

        <div className="flex flex-col justify-end items-center flex-none w-full">
          <div className="flex flex-col px-6 py-6 gap-4 w-[752px] max-w-full">
            <ChatInput onSubmit={onTextSubmit} />
            <div className="flex gap-8 mt-2">
              <ModelSettings
                availableModels={availableModels}
                changeModel={changeModel}
                selectedModel={selectedModel}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="absolute bottom-8 right-8">
        <InforIcon />
      </div>
    </div>
  );
}
