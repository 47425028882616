import styled from "styled-components";
export const greyColor = "#76777a";

export const ChatMessagesContainer = styled.div`
  &::-webkit-scrollbar {
    width: 10px;
    height: 20px;
  }

  &::-webkit-scrollbar-track {
    width: 1px;
    background: #d9d9d9;
    border-radius: 100vh;
  }

  &::-webkit-scrollbar-thumb {
    background: #d09560;
    border-radius: 100vh;
  }
`;
