import axios from "axios";

const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
const ENDPOINT = process.env.REACT_APP_OPENAI_URL;


export class OpenAiIntegration {
  static Instance = new OpenAiIntegration();
  constructor() {}

  async sendPrompt(prompt, instruction = "") {
    const headers = {
      "Content-Type": "application/json",
      "api-key": API_KEY,
    };

    if (!instruction) {
      instruction = "You are an AI assistant trying to help people with as precise information as possible";
    }

    const payload = {
      model: 'gpt-4o',
      messages: [
        {
          role: "system",
          content: [
            {
              type: "text",
              text: instruction,
            },
          ],
        },
        {
          role: "user",
          content: [
            {
              type: "text",
              text: prompt,
            },
          ],
        },
      ]
    };

    const response = await axios.post(ENDPOINT, payload, { headers });

    return response;
  }
}
