import { useState } from "react";
import { ChatModelSelectionItem } from "./ChatModelSelectionItem";

export function ChatModelSelection({models, selectedModel, changeModel}) {
  return (
    <div className="w-[318px] px-2 py-4 bg-[#3D3D3D] rounded-lg border border-[#76777A] flex flex-col justify-center items-center gap-2">
      {models.map((model, index) => (
        <ChatModelSelectionItem
          key={index}
          modelName={model.modelName}
          description={model.description}
          isSelected={selectedModel.modelName === model.modelName}
          onCheckChanged={() => changeModel(model)}
        />
      ))}
    </div>
  );
}
