import { CheckedIcon, UncheckedIcon } from "../assets";

export function ChatModelSelectionItem({
  modelName,
  description,
  isSelected,
  onCheckChanged,
}) {
  return (
    <div
      className="w-full py-2 px-3 flex justify-end cursor-pointer items-start gap-4 hover:bg-[#313131]"
      onClick={onCheckChanged}
    >
      <div className="flex-1 flex flex-col justify-center items-start gap-1">
        <div className="text-white text-base font-size-[1rem] font-normal break-words">
          {modelName}
        </div>
        <div className="w-full text-[#E7E9E9] text-sm font-normal break-words">
          {description}
        </div>
      </div>
      {isSelected ? <CheckedIcon /> : <UncheckedIcon />}
    </div>
  );
}
